import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Typography from '@material-ui/core/Typography/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  Footer,
  Layout,
  HomeText,
} from '../containers';
import {
  Container,
  GradientBackground,
  StyledButton,
  HeroContainer, HomeTextContainer,
} from '../styled';

import { COLORS } from '../utils/constants';

class FaqPage extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  state = {
    expanded: null,
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  render() {
    const { expanded } = this.state;
    const { data } = this.props;
    const { allMarkdownRemark } = data;
    const { edges } = allMarkdownRemark;
    const { contact } = edges.filter(el => el.node.frontmatter.contact)[0].node.frontmatter;
    const { faq } = edges.filter(el => el.node.frontmatter.faq)[0].node.frontmatter;
    const {
      faq_heading,
      faq_button,
      faq_articles,
    } = faq;

    return (
      <Layout>
            <React.Fragment>
              <HomeTextContainer marginTop={8}>
                <Container>
                  <p style={{fontSize: '30px', fontWeight:'600'}}>
                    TCL  /  Hertz  /  NFM  /  Klinika Bocian  /  Stena Recycling  /  Gardena  /  BBC Earth  /
                    Fossil  /  IFB  /  Women in Tech Summit  /  Orange  /  Netflix  /  McFIT  /  DOM Medica  /  H+K Strategies  /
                    Warsaw Metro  /  Nocowanie.pl  /  Butcher's /  European Aquascaping Championship  /  Dr Gerard
                  </p>
                </Container>
              </HomeTextContainer>
            </React.Fragment>
            <HomeText marginTop={3}></HomeText>
        <Footer contactSectionContent={contact} />
      </Layout>
    );
  }
}

export default FaqPage;

export const query = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            contact {
              contact_address_heading
              contact_address_email
              contact_address_website
              contact_address_street
              contact_address_city
              contact_social_heading
              contact_copyright_informations
              contact_cookies_information
              contact_cookies_link
              contact_gdpr_informations
              contact_gdpr_link
            }
            faq {
              faq_heading
              faq_button
              faq_articles {
                faq_article {
                  faq_header
                  faq_content
                }
              }
            }
          }
        }
      }
    }
  }
`;
